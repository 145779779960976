import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { TrashIcon } from '@heroicons/react/outline'
import toast from 'react-hot-toast'

import { getAugmentorFeedList } from 'api/asset'
import Filters from './Filters'
import ListTable from 'components/ListTable'

function ImportedFiles () {
  const [reset, setReset] = useState(false)
  const [loading, setLoading] = useState()
  const [fileList, setFileList] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [rownum, setRownum] = useState(10)

  const hasNext = currentPage < totalPages

  useEffect(() => {
    getList()
  }, [currentPage, rownum])

  useEffect(() => {
    if (reset) {
      getList()
      setReset(false)
    }
  }, [reset])

  const getList = () => {
    setLoading(true)
    getAugmentorFeedList(currentPage, rownum).then(res => {
      setTotalPages(res.data.total_pages)
      setFileList(res.data.results)
      setLoading(false)
    })
  }

  // Functions related to filtering the list of imported files
  const onPageChange = (evt) => {
    const cPage = evt.target.value
    if (cPage !== '' && parseInt(cPage) !== currentPage) {
      setCurrentPage(cPage)
    }
  }

  const onNext = (page) => {
    setCurrentPage(page + 1)
  }

  const onPrevious = (page) => {
    if (page !== 1) {
      setCurrentPage(page - 1)
    }
  }

  const handleRowChange = (evt) => {
    const rNum = parseInt(evt.target.value)
    setRownum(rNum)
  }

  const assetColumns = [
    {
      Header: 'Filename',
      Cell: ({ row }) => {
        const names = row.original.filename.split(' ').map(name => name.trim());
        // Group names to keep this to four strings per row
        const groupedNames = [];
        for (let i = 0; i < names.length; i += 4) {
          groupedNames.push(names.slice(i, i + 4).join(' '));
        }
        return (<>
            {row.original.status === 'processed' && row.original.output_link
              ? (<a className="font-bold hover:text-blue-500" href={row.original.output_link} target='_blank'>
                  {groupedNames.map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </a>)
              : (<span>
                {groupedNames.map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </span>)
            }
          </>);
      }
    },
    {
      Header: 'Uploader',
      Cell: ({ row }) => {
        const names = row.original.created_by.split(' ').map(name => name.trim());
        // Group names into pairs to keep this to two strings per row
        const groupedNames = [];
        for (let i = 0; i < names.length; i += 2) {
          groupedNames.push(names.slice(i, i + 2).join(' '));
        }
        return (
          <span>
            {groupedNames.map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </span>
        );
      }
    },
    {
      Header: 'Upload Date',
      Cell: ({ row }) => {
        const formattedDate = new Date(row.original.created).toLocaleString();
        const [date, time] = formattedDate.split(', ');
        return (<span>{date}<br />{time}</span>);
      }
    },
    {
      Header: 'Date of Completion',
      Cell: ({ row }) => {
        const formattedDate = new Date(row.original.date_of_completion).toLocaleString();
        const [date, time] = formattedDate.split(', ');
        return (
          <>
            {row.original.status !== 'processing' && (
              <span>
                {date}<br />{time}
              </span>
            )}
          </>
        );
      }
    },
    {
      Header: 'Status',
      id: 'status',
      Cell: ({ row }) => (<div className='flex'>
        {row.original.status === 'processed'
          ? (<label
            className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
          >
            Processed
          </label>)
          : row.original.status === 'invalid'
          ? (<label
              className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10"
            >
              Invalid
            </label>)
          : (<label
            className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20"
          >
            Processing ({row.original.percentage}%)
          </label>)
        }
      </div>
      )
    },
    {
      Header: 'Download',
      id: 'output_link',
      Cell: ({ row }) => (<div className='flex'>
        {row.original.status === 'processed'
          ? (<a
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 cursor-pointer"
            href={row.original.output_link}
            target='_blank'
          >
            DOWNLOAD
          </a>)
          : null
        }
      </div>
      )
    }
  ]

  return (
    <div className='divide-y divide-gray-200 bg-white shadow sm:rounded-md p-6'>
      <div>
        <h3 className="mb-5 text-lg leading-6 font-medium text-gray-900">Uploaded Files</h3>
      </div>
      <ListTable
        columns={assetColumns}
        data={fileList}
        getNext={onNext}
        getPrev={onPrevious}
        currPage={currentPage}
        setCurrPage={setCurrentPage}
        hasNext={hasNext}
        pages={totalPages}
        showPagination
      />
    </div>
  )
}

export default ImportedFiles
