import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { TrashIcon } from '@heroicons/react/outline'
import toast from 'react-hot-toast'

import { getAugmentorAssetFeedList } from 'api/asset'
import Filters from './Filters'
import ListTable from 'components/ListTable'

function ImportedFiles () {
  const [reset, setReset] = useState(false)
  const [loading, setLoading] = useState()
  const [fileList, setFileList] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [rownum, setRownum] = useState(10)

  const hasNext = currentPage < totalPages

  useEffect(() => {
    getList()
  }, [currentPage, rownum])

  useEffect(() => {
    if (reset) {
      getList()
      setReset(false)
    }
  }, [reset])

  const getList = () => {
    setLoading(true)
    getAugmentorAssetFeedList(currentPage, rownum).then(res => {
      setTotalPages(res.data.total_pages)
      setFileList(res.data.results)
      setLoading(false)
    })
  }

  // Functions related to filtering the list of imported files
  const onPageChange = (evt) => {
    const cPage = evt.target.value
    if (cPage !== '' && parseInt(cPage) !== currentPage) {
      setCurrentPage(cPage)
    }
  }

  const onNext = (page) => {
    setCurrentPage(page + 1)
  }

  const onPrevious = (page) => {
    if (page !== 1) {
      setCurrentPage(page - 1)
    }
  }

  const handleRowChange = (evt) => {
    const rNum = parseInt(evt.target.value)
    setRownum(rNum)
  }

  const assetColumns = [
    {
      Header: 'Filename',
      Cell: ({ row }) => (<span>{row.original.filename}</span>)
    },
    {
      Header: 'Upload Date',
      Cell: ({ row }) => (
        <span>{new Date(row.original.created).toLocaleString()}</span>
      )
    },
    {
      Header: 'Notes',
      accessor: 'notes'
    },
    {
      Header: 'Status',
      id: 'status',
      Cell: ({ row }) => (<div className='flex'>
        {row.original.status === 'processed'
          ? (<label
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            PROCESSED
          </label>)
          : row.original.status === 'invalid'
          ? (<label
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              INVALID
            </label>)
          : (<label
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          >
            PROCESSING
          </label>)
        }
      </div>
      )
    }
  ]

  return (
    <div className='divide-y divide-gray-200 bg-white shadow sm:rounded-md p-6'>
      <div>
        <h3 className="mb-5 text-lg leading-6 font-medium text-gray-900">Uploaded Files</h3>
      </div>
      <div className='pt-8'>
        <div className='col-md-12'>
          <ListTable
            columns={assetColumns}
            data={fileList}
            getNext={onNext}
            getPrev={onPrevious}
            currPage={currentPage}
            setCurrPage={setCurrentPage}
            hasNext={hasNext}
            pages={totalPages}
            showPagination
          />
        </div>
      </div>
    </div>
  )
}

export default ImportedFiles
