import PrivateAPI from './private'

export const uploadAugmentorFeedFile = (payload) => {
  return PrivateAPI.postfile('api/assets/', payload)
}

export const getPresignedUrl = (payload) => {
  return PrivateAPI.post('api/assets/presigned-url/', payload)
}

export const notifyUploadComplete = (payload) => {
  return PrivateAPI.post('api/assets/upload-complete/', payload)
}

export const getAugmentorFeedList = (page, rownum) => {
  var params = {}

  params['page'] = page
  params['rownum'] = rownum

  return PrivateAPI.get('api/assets/', params)
}

export const getAugmentorFeedRows = (id, page, rownum) => {
  var params = {}

  params['page'] = page
  params['rownum'] = rownum

  return PrivateAPI.get(`api/assets/${id}/`, params)
}

export const uploadAugmentorAssetFeedFile = (payload) => {
  return PrivateAPI.postfile('api/assets/asset-feed/', payload)
}

export const fetchAssets = (page, rownum, term) => {
  var params = {}
  if (term !== undefined && term !== '') {
    params['_filter'] = term
  }

  params['page'] = page
  params['rownum'] = rownum

  return PrivateAPI.get('api/assets/asset-search/', params)
}


export const getAugmentorAssetFeedList = (page, rownum) => {
  var params = {}

  params['page'] = page
  params['rownum'] = rownum

  return PrivateAPI.get('api/assets/asset-feed/', params)
}
