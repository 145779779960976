import PrivateAPI from './private'

export const getList = (payload) => {
  console.log('Getting list with params', payload)

  let params = {}
  let page = payload.page || 1

  if (page > 0) {
    params['page'] = page
  }

  if (payload.rownum !== undefined) {
    params['rownum'] = payload.rownum
  }

  if (payload.q !== undefined && payload.q !== '') {
    params['q'] = payload.q
  }

  if (payload.sort !== undefined) {
    params['sort'] = payload.sort
  }

  if (payload.f !== undefined) {
    params['f'] = payload.f
  }

  return PrivateAPI.get('api/compositions/recordings/list/exp/', params)
}

export const getPageList = (payload) => {
  console.log('Getting list with params', payload)

  let params = {}
  let page = payload.page || 1

  if (page > 0) {
    params['page'] = page
  }

  if (payload.q !== undefined && payload.q !== '') {
    params['q'] = payload.q
  }

  if (payload.sort !== undefined) {
    params['sort'] = payload.sort
  }

  return PrivateAPI.get('api/compositions/recordings/list/', params)
}

export const fetch = (pk) => {
  return PrivateAPI.get('api/compositions/recordings/' + pk + '/')
}

export const fetchE = (pk) => {
  return PrivateAPI.get('api/compositions/recordings/e/' + pk + '/')
}

export const create = (payload) => {
  return PrivateAPI.post('api/compositions/recordings/list/', payload)
}

export const edit = (payload, pk) => {
  if (typeof (payload.composition) === 'object') {
    payload.composition = payload.composition.pk
  }

  return PrivateAPI.put('api/compositions/recordings/' + (pk) + '/', payload)
}

export const link = (payload) => {
  return PrivateAPI.post('api/compositions/recordings/link/', payload)
}

export const del = (recordingId) => {
  return PrivateAPI.delete('api/compositions/recordings/' + recordingId + '/')
}

export const fetchRecordings = () => {
  return PrivateAPI.get('api/compositions/recordings/')
}

export const fetchCompositions = () => {
  return PrivateAPI.get('api/compositions/options/')
}

export const setPrimaryRecording = (pk) => {
  return PrivateAPI.get('api/compositions/recordings/primary/' + (pk) + '/')
}