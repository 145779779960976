import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Form, Col } from 'react-bootstrap'
import toast from 'react-hot-toast'

import SelectField from 'layouts/components/Select.js'
import { undoDelete } from 'api/tools'

const UndoDeleteView = () => {
  const history = useHistory()

  const { register, handleSubmit, errors, control, setValue } = useForm({
    mode: 'onChange'
  })

  const onSubmit = (values) => {
    let data = {}
    data['ids'] = values['ids'].split("\n")

    if (window.confirm('Undo delete for these assets?')) {
      undoDelete(data).then(res => {
        if (res.status === 200) {
          history.push('/dashboard/')
          toast.success('Success! Some assets may not yet appear in the app since it may take some time to return them all.')
        } else {
          toast.error('Unable to undo delete for these assets')
        }
      }).catch((err) => {
        console.log(err.response)
        toast.error('You do not have permission to perform this action.')
      })
    }
  }

  return (<>
    <div className="flex flex-1 min-h-full">
      <div className="p-6 flex-1">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Undo Delete</h2>
        </div>
        <Form className='divide-y divide-gray-200 bg-white shadow sm:rounded-md p-6' onSubmit={handleSubmit(onSubmit)}>
          <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
            <div className="sm:col-span-3 space-y-6 sm:space-y-5">
              <div className="sm:col-span-6">
                <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
                  Model IDs
                </label>
                <p className="text-sm text-gray-500">One ID per line.</p>
                <div className="mt-1">
                  <textarea
                    id="notes"
                    name="notes"
                    rows={3}
                    className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                    defaultValue={''}
                    {...register('ids', { required: true })}
                  />
                  {errors && errors.ids && (<Form.Control.Feedback type="invalid">
                    {errors.ids.type === 'required'
                      ? 'This field is required'
                      : errors.ids.message
                  }
                  </Form.Control.Feedback>)}
                </div>
              </div>

              <div className="pt-5">
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={errors}
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Undo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </>)
}

export default UndoDeleteView
