import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { DateRange } from 'react-date-range'
import Select from 'react-select'
import { format } from 'date-fns'
import toast from 'react-hot-toast'
import { Form } from 'react-bootstrap'
import { Switch } from '@headlessui/react'

import PublisherSelectModal from 'layouts/components/PublisherModalSelect/PublisherSelect'
import AssignToPublisher from './AssignToPublisher'

import { getFilteredExportFile } from 'api/download'

const DateBox = styled.div`
  margin-top: 10px;
`

const StyledDateRange = styled(DateRange)`
`

const shareFieldOptions = [
  { value: 'ISWC', label: 'ISWC' },
  { value: 'MLC/HFA Code', label: 'MLC/HFA Code' },
  { value: 'CMRRA Work ID', label: 'CMRRA Work ID' },
  { value: 'YouTube Comp Share ID', label: 'YouTube Comp Share ID' },
  { value: 'Percentage', label: 'Percentage' }
]

const recordingFieldOptions = [
  { value: 'YouTube SR Asset ID', label: 'YouTube SR Asset ID' },
  { value: 'Percentage', label: 'Percentage' },
  { value: 'ISRC', label: 'ISRC' }
]

const shareRecordingFieldOptions = [
  { value: 'ISWC', label: 'ISWC' },
  { value: 'MLC/HFA Code', label: 'MLC/HFA Code' },
  { value: 'CMRRA Work ID', label: 'CMRRA Work ID' },
  { value: 'YouTube Comp Share ID', label: 'YouTube Comp Share ID' },
  { value: 'YouTube SR Asset ID', label: 'YouTube SR Asset ID' },
  { value: 'Percentage', label: 'Percentage' },
  { value: 'ISRC', label: 'ISRC' }
]

const toggleOptions = [
  { value: 'shares', label: 'Shares' },
  { value: 'recordings', label: 'Recordings' },
  { value: 'share_recordings', label: 'Shares & Recordings' }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Filters ({ setBulkItems, toggleExport, setToggleExport }) {
  const history = useHistory()
  const [exportModel, setExportModel] = useState('shares')
  const [dateFilter, setDateFilter] = useState([
    {
      startDate: null,
      endDate: new Date(""),
      key: 'selection'
    }
  ])
  const [modifiedFields, setModifiedFields] = useState()
  const [selectPublisherModal, setSelectPublisherModal] = useState(false)
  const [selectedPublishers, setSelectedPublishers] = useState([])
  const [selectedPublisherField, setSelectedPublisherField] = useState('')
  const [relinquished, setRelinquished] = useState(false)

  const handleDateChange = (data) => {
    setDateFilter(data)
  }

  const assignPublisher = (pub) => {
    let publishers = selectedPublishers
    let publisherField = selectedPublisherField

    if (publishers.includes(pub)) {
      publishers.splice(publishers.indexOf(pub), 1)

      let pubString = ', ' + pub.pub_name

      if (publisherField.includes(pubString)) {
        publisherField = publisherField.replace(pubString, '')
      } else {
        publisherField = publisherField.replace(pub.pub_name + ', ', '')
      }
    } else {
      publishers.push(pub)

      if (publisherField.length > 0) {
        publisherField = publisherField + ', ' + pub.pub_name
      } else {
        publisherField = pub.pub_name
      }
    }

    setSelectedPublisherField(publisherField)
    setSelectedPublishers(publishers)
  }

  const toggleSelectPublisherModal = () => {
    setSelectPublisherModal(!selectPublisherModal)
  }

  const _handleExport = (typer) => {
    let dataModifiedFields = []
    if (modifiedFields) {
      modifiedFields.forEach(platform => dataModifiedFields.push(platform.value))
    }
  
    const startDate = dateFilter && dateFilter[0].startDate && dateFilter[0].startDate ? format(dateFilter[0].startDate, 'yyyy-MM-dd') : null
    const endDate = dateFilter && dateFilter[0].startDate && dateFilter[0].endDate ? format(dateFilter[0].endDate, 'yyyy-MM-dd') : null

    let publisherList = []
    if (selectedPublishers !== undefined) {
      publisherList = selectedPublishers.map((pub, idx) => {
        return pub.pk
      })
    }

    let payload = {
      'exportModel': exportModel,
      'exportType': typer,
      'modifiedFields': dataModifiedFields,
      'startDate': startDate,
      'endDate': endDate,
      'publishers': publisherList,
      'relinquished': relinquished
    }

    getFilteredExportFile(payload).then(res => {
      if (res.status === 200) {
        history.push('/download/my-exports/?date=' + res.data.date)
      } else {
        toast.error('Unable to generate export file.')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to generate export file')
    })
  }

  const getOptions = () => {
    if (exportModel === 'share_recordings') {
      return shareRecordingFieldOptions
    } else if (exportModel === 'recordings') {
      return recordingFieldOptions
    } else {
      return shareFieldOptions
    }
  }

  const _handleReset = () => {
    setDateFilter([{
      startDate: null,
      endDate: new Date(""),
      key: 'selection'
    }])
    setExportModel('shares')
    setModifiedFields([])
  }

  const shareBulkItems = [
    {
      name: 'Exploration Format',
      submenu: [
        {
          name: 'Shares',
          action: _handleExport,
          params: 'expshares'
        },
        {
          name: 'Recordings',
          action: _handleExport,
          params: 'exprecordings'
        },
        {
          name: 'Both',
          action: _handleExport,
          params: 'expsharesrecordings'
        },
      ]
    },
    {
      name: 'YouTube Format',
      action: _handleExport,
      params: 'youtube',
      label: 'Export'
    },
    {
      name: 'SubPublishing Format',
      action: _handleExport,
      params: 'subpublishing',
      label: 'Export'
    },
    {
      name: 'BMI SE Format', 
      submenu: [
        {
          name: 'Exploration',
          action: _handleExport,
          params: 'bmiseexploration'
        },
        {
          name: 'Client',
          action: _handleExport,
          params: 'bmiseclient'
        }
      ]
    },
    {
      name: 'BMI Admin Format',
      action: _handleExport,
      params: 'bmiadmin',
      label: 'Export'
    },
    {
      name: 'BMI Original Format',
      action: _handleExport,
      params: 'bmioriginal',
      label: 'Export'
    },
    {
      name: 'MusicMark Format',
      action: _handleExport,
      params: 'musicmark',
      label: 'Export'
    },
    {
      name: 'MRI Format', 
      submenu: [
        {
          name: 'Exploration',
          action: _handleExport,
          params: 'mriexploration'
        },
        {
          name: 'Client',
          action: _handleExport,
          params: 'mriclient'
        }
      ]
    },
    {
      name: 'HFA V1 Format', 
      submenu: [
        {
          name: 'Exploration',
          action: _handleExport,
          params: 'hfaexploration'
        },
        {
          name: 'Client',
          action: _handleExport,
          params: 'hfaclient'
        }
      ]
    },
    {
      name: 'HFA V2 Format', 
      submenu: [
        {
          name: 'Exploration',
          action: _handleExport,
          params: 'hfafullexp'
        },
        {
          name: 'Client',
          action: _handleExport,
          params: 'hfafullclient'
        }
      ]
    },
    {
      name: 'MLC Format', 
      submenu: [
        {
          name: 'Exploration',
          action: _handleExport,
          params: 'mlcexploration'
        },
        {
          name: 'Client',
          action: _handleExport,
          params: 'mlcclient'
        }
      ]
    },
    {
      name: 'CMRRA Format', 
      submenu: [
        {
          name: 'V1',
          action: _handleExport,
          params: 'cmrrav1'
        },
        {
          name: 'V2',
          action: _handleExport,
          params: 'cmrrav2'
        }
      ]
    },
    {
      name: 'LyricFind Format',
      action: _handleExport,
      params: 'lyricfind',
      label: 'Export'
    },
    {
      name: 'Shelly Bay Format',
      action: _handleExport,
      params: 'shellybay',
      label: 'Export'
    }
  ]

  const recordingBulkItems = [
    {
      name: 'Exploration Format',
      label: 'Export',
      action: _handleExport,
      params: 'exprecordings'
    },
    {
      name: 'SoundExchange Format', 
      submenu: [
        {
          name: 'Exploration',
          action: _handleExport,
          params: 'seexploration'
        },
        {
          name: 'Client',
          action: _handleExport,
          params: 'seclient'
        }
      ]
    },
    {
      name: 'DDEX Format', 
      submenu: [
        {
          name: 'YouTube',
          action: _handleExport,
          params: 'ytddex'
        },
        {
          name: 'Soundcloud',
          action: _handleExport,
          params: 'scddex'
        }
      ]
    }
  ]

  const toggleBulkOptions = () => {
    if (exportModel === 'recordings') {
      setBulkItems(recordingBulkItems)
    } else {
      setBulkItems(shareBulkItems)
    }
    setToggleExport(!toggleExport)
  }

  return (
    <div className="mt-6 py-6">
      <PublisherSelectModal
        status={selectPublisherModal}
        toggleModal={toggleSelectPublisherModal}
        assignPublisher={assignPublisher}
        selectedPublishers={selectedPublishers}
      />
      <h3 className="text-lg leading-6 font-medium text-gray-900">Filtered Exports</h3>
      <Form>
        <div className='mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-6'>
          <div className='sm:col-span-3'>
            <DateBox>
              <label htmlFor="uploadDate" className="block text-sm font-medium text-gray-700">
                Date Modified
              </label>
              <input type='hidden' name='startDate' />
              <input type='hidden' name='endDate' />
              <StyledDateRange
                editableDateInputs
                onChange={item => handleDateChange([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dateFilter}
              />
            </DateBox>
          </div>
          <div className='sm:col-span-3 space-y-6 sm:space-y-5'>
            <div className="sm:col-span-3">
              <label htmlFor="exportType" className="block text-sm font-medium text-gray-700">
                Export Type
              </label>
              <div className="mt-1">
                <select
                  id="exportType"
                  name="export_type"
                  autoComplete="export_type"
                  onChange={(e) => setExportModel(e.target.value)}
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                >
                  {toggleOptions.map((option, idx) => (
                    <option key={idx} value={option.value}>{option.label}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="exportType" className="block text-sm font-medium text-gray-700">
                Modified Fields
              </label>
              <div className="mt-1">
                <Select
                  options={getOptions()}
                  value={modifiedFields}
                  onChange={(e) => {
                    setModifiedFields(e)
                  }}
                  isMulti
                />
              </div>
            </div>

            {(exportModel === 'share_recordings' || exportModel === 'shares') &&
              (<>
                <div className="sm:col-span-3">
                  <label htmlFor="relatedPublishers" className="block text-sm font-medium text-gray-700">
                    Related Publishers
                  </label>
                  <div className="mt-1">
                    <AssignToPublisher
                      className='form-control'
                      value={selectedPublisherField}
                      toggleSelectPublisherModal={toggleSelectPublisherModal}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <Switch.Group as="div" className="flex items-center justify-between">
                    <span className="flex-grow flex flex-col">
                      <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                        Relinquished?
                      </Switch.Label>
                      <Switch.Description as="span" className="text-sm text-gray-500">
                        Include only relinquished shares.
                      </Switch.Description>
                    </span>
                    <Switch
                      checked={relinquished}
                      onChange={setRelinquished}
                      className={classNames(
                        relinquished ? 'bg-red-600' : 'bg-gray-200',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          relinquished ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
              </>)
            }
            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => _handleReset()}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Reset
                </button>
                {exportModel === 'share_recordings'
                  ? (<button
                      type="button"
                      onClick={() => _handleExport('expsharesrecordings')}
                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      Export
                    </button>)
                  : (<button
                    type="button"
                    onClick={() => toggleBulkOptions()}
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Export
                  </button>)
                }
              </div>
            </div>
          </div>
        </div>
      </Form>
  </div>)
}

export default Filters
