import React from 'react';
import { Route, Switch } from 'react-router';
import Feed from './routes/Feed/index';
import AssetFeed from './routes/AssetFeed/index';
import Detail from './routes/Detail/index';
import Uploading from './routes/Uploading/index';
import AssetSearch from './routes/AssetSearch/index';

export default function AugmentorViews () {
  return (
    <Switch>
      <Route exact path='/augmentor/' component={Feed} />
      <Route exact path='/augmentor/feed/' component={AssetFeed} />
      <Route exact path='/augmentor/assets/' component={AssetSearch} />
      <Route path='/augmentor/upload/:id' component={Uploading} />
      <Route path='/augmentor/:id' component={Detail} />
    </Switch>
  )
}