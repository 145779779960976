import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import toast from 'react-hot-toast'
import { FolderDownloadIcon } from '@heroicons/react/outline'

import BulkOptions from 'components/BulkOptions'
import './components/List.scss'
import Filters from './components/Filters'
import ExportCards from './components/ExportCards'

import { getData,
  getFile,
  getRecordingsFile,
  getDistributionFile,
  getWritersFile,
  getPublishersFile,
  getPublishingFile } from 'api/download'

const ExportView = () => {
  const history = useHistory()
  const [summary, setSummary] = useState()
  const [toggleExport, setToggleExport] = useState(false)
  const [bulkItems, setBulkItems] = useState()

  useEffect(() => {
    getData().then(res => {
      if (res.status === 200) {
        setSummary(res.data.summary)
      } else {
        toast.error('Unable to fetch summary')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to fetch summary')
    })
  }, [])

  const getExportShares = (typer) => {
    getFile(typer).then(res => {
      if (res.status === 200) {
        history.push('/download/my-exports/?date=' + res.data.date)
      } else {
        toast.error('Unable to get export')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to get export')
    })
  }

  const getExportRecordings = (typer) => {
    getRecordingsFile(typer).then(res => {
      if (res.status === 200) {
        history.push('/download/my-exports/?date=' + res.data.date)
      } else {
        toast.error('Unable to get export')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to get export')
    })
  }

  const getDistributionRecordings = () => {
    getDistributionFile().then(res => {
      if (res.status === 200) {
        history.push('/download/my-exports/?date=' + res.data.date)
      } else {
        toast.error('Unable to get export')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to get export')
    })
  }

  const getExportWriters = (typer) => {
    getWritersFile(typer).then(res => {
      if (res.status === 200) {
        history.push('/download/my-exports/?date=' + res.data.date)
      } else {
        toast.error('Unable to get export')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to get export')
    })
  }

  const getExportPublishers = () => {
    getPublishersFile().then(res => {
      if (res.status === 200) {
        history.push('/download/my-exports/?date=' + res.data.date)
      } else {
        toast.error('Unable to get export')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to get export')
    })
  }

  const getExportPublishing = () => {
    getPublishingFile().then(res => {
      if (res.status === 200) {
        history.push('/download/my-exports/?date=' + res.data.date)
      } else {
        toast.error('Unable to get export')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to get export')
    })
  }

  if (summary === undefined) {
    return null
  }

  const shareBulkItems = [
    {
      name: 'Exploration Format',
      submenu: [
        {
          name: 'Shares',
          action: getExportShares,
          params: 'expshares'
        },
        {
          name: 'Recordings',
          action: getExportShares,
          params: 'exprecordings'
        },
        {
          name: 'Both',
          action: getExportShares,
          params: 'expsharesrecordings'
        },
      ]
    },
    {
      name: 'YouTube Format',
      action: getExportShares,
      params: 'youtube',
      label: 'Export'
    },
    {
      name: 'SubPublishing Format',
      action: getExportShares,
      params: 'subpublishing',
      label: 'Export'
    },
    {
      name: 'BMI SE Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExportShares,
          params: 'bmiseexploration'
        },
        {
          name: 'Client',
          action: getExportShares,
          params: 'bmiseclient'
        }
      ]
    },
    {
      name: 'BMI Admin Format',
      action: getExportShares,
      params: 'bmiadmin',
      label: 'Export'
    },
    {
      name: 'BMI Original Format',
      action: getExportShares,
      params: 'bmioriginal',
      label: 'Export'
    },
    {
      name: 'MusicMark Format',
      action: getExportShares,
      params: 'musicmark',
      label: 'Export'
    },
    {
      name: 'MRI Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExportShares,
          params: 'mriexploration'
        },
        {
          name: 'Client',
          action: getExportShares,
          params: 'mriclient'
        }
      ]
    },
    {
      name: 'HFA V1 Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExportShares,
          params: 'hfaexploration'
        },
        {
          name: 'Client',
          action: getExportShares,
          params: 'hfaclient'
        }
      ]
    },
    {
      name: 'HFA V2 Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExportShares,
          params: 'hfafullexp'
        },
        {
          name: 'Client',
          action: getExportShares,
          params: 'hfafullclient'
        }
      ]
    },
    {
      name: 'MLC Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExportShares,
          params: 'mlcexploration'
        },
        {
          name: 'Client',
          action: getExportShares,
          params: 'mlcclient'
        }
      ]
    },
    {
      name: 'CMRRA Format', 
      submenu: [
        {
          name: 'V1',
          action: getExportShares,
          params: 'cmrrav1'
        },
        {
          name: 'V2',
          action: getExportShares,
          params: 'cmrrav2'
        }
      ]
    },
    {
      name: 'LyricFind Format',
      action: getExportShares,
      params: 'lyricfind',
      label: 'Export'
    },
    {
      name: 'Shelly Bay Format',
      action: getExportShares,
      params: 'shellybay',
      label: 'Export'
    }
  ]

  const recordingBulkItems = [
    {
      name: 'Exploration Format',
      label: 'Export',
      action: getExportRecordings,
      params: 'exprecordings'
    },
    {
      name: 'Distribution Only',
      label: 'Export',
      action: getDistributionRecordings
    },
    {
      name: 'SoundExchange Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExportRecordings,
          params: 'seexploration'
        },
        {
          name: 'Client',
          action: getExportRecordings,
          params: 'seclient'
        }
      ]
    },
    {
      name: 'DDEX Format', 
      submenu: [
        {
          name: 'YouTube',
          action: getExportRecordings,
          params: 'ytddex'
        },
        {
          name: 'Soundcloud',
          action: getExportRecordings,
          params: 'scddex'
        }
      ]
    }
  ]

  const publishingBulkItems = [
    {
      name: 'Writers', 
      submenu: [
        {
          name: 'All',
          action: getExportWriters,
          params: 'all'
        },
        {
          name: 'Controlled',
          action: getExportWriters,
          params: 'controlled'
        }
      ]
    },
    {
      name: 'Publishers',
      label: 'Export',
      action: getExportPublishers
    },
    {
      name: 'Writers & Publishers',
      label: 'Export',
      action: getExportPublishing
    }
  ]

  const toggleBulkOptions = (typer) => {
    if (typer === 'publishing') {
      setBulkItems(publishingBulkItems)
    } else if (typer === 'recordings') {
      setBulkItems(recordingBulkItems)
    } else {
      setBulkItems(shareBulkItems)
    }
    setToggleExport(!toggleExport)
  }

  return (
    <>
      <div className="flex flex-1 min-h-full">
        <div className="p-6 flex-1">
          <div className="mb-2 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Export Data</h2>
            </div>
            <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
              <Link to='/download/my-exports/?date='>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none"
                >
                  My Downloads<FolderDownloadIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                </button>
              </Link>
            </div>
          </div>

          <div className='mt-4 divide-y divide-gray-200 bg-white shadow sm:rounded-md p-6'>
            <ExportCards summary={summary} toggleBulkOptions={toggleBulkOptions} />
            <Filters setBulkItems={setBulkItems} toggleExport={toggleExport} setToggleExport={setToggleExport} />
          </div>
        </div>
        { toggleExport &&
          (<div className="flex-grow-0 flex-shrink-0">
            <BulkOptions items={bulkItems} onClose={setToggleExport} />
          </div>)
        }
      </div>
    </>
  )
}

export default ExportView
